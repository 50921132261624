<template lang="html">

  <section class="app-footer">
    <div class="row pre-footer px-5 py-3">
      <div class="col-12 col-md-8 text-center-responsive">
      <a href="http://www.famp.es/es/redes-observatorios/redema/" target="_blank">
        <img src="/static/img/logos/FAMP.png" class="mr-5">
      </a>
      <img src="/static/img/logos/EU_Development_Fund.png">
      <img src="/static/img/logos/SUPPORT.jpg">
      </div>
      <div class="col-12 col-md-4 text-right text-center-responsive">
        <img src="/static/img/logos/uspace_agenda_2030.png">
      </div>
    </div>
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 REDEMA. Todos los derechos reservados</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center text-muted">Desarrollado por <a href="https://enreda.coop/" target="_blank">ENREDA</a></span>
      </div>
    </footer>
  </section>

</template>

<script lang="js">
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="scss">
.pre-footer img {
  max-height: 75px;
  max-width: 200px;
}
</style>
