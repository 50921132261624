<template>
  <section class="main-view">
    <div class="container-scroller">
      <app-header></app-header>
      <div class="container-fluid page-body-wrapper">
        <app-sidebar></app-sidebar>
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view></router-view>
          </div>
          <!-- content wrapper ends -->
          <app-footer/>
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppSidebar from '@/components/AppSidebar'
import AppFooter from '@/components/AppFooter'
export default {
  name: 'Home',
  components: {
    AppHeader,
    AppSidebar,
    AppFooter
  },
};
</script>

<style lang='scss' scoped>
.page {
  margin: 30px;
}
</style>
