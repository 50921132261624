<template lang="html">
  <b-navbar id="template-header" class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row" toggleable="md">
    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
      <router-link class="navbar-brand brand-logo-mini img-white" to="/">
        <img src="/static/img/logos/REDEMA_icon_white.png" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <!-- Button menu -->
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" @click="collapedSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>
      <!-- . Button menu -->

      <b-navbar-nav class="navbar-nav navbar-nav-left header-links d-none d-md-flex">
        <b-nav-item class="font-weight-semibold d-none d-lg-block" :to="{ name: 'listBuildings' }">Observatorio Regional de la Energía REDEMA</b-nav-item>
      </b-navbar-nav>

      <!-- Mobile button menu -->
      <button class="navbar-toggler navbar-toggler-right align-self-center" type="button" @click="collapedMobileSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>
      <!-- . Mobile button menu -->
    </div>
  </b-navbar>
</template>

<script lang="js">
import JQuery from 'jquery'
// eslint-disable-next-line
let $ = JQuery

export default {
  name: 'app-header',
  methods: {
    collapedSidebar: function () {
      $('body').toggleClass('sidebar-icon-only')
    },
    collapedMobileSidebar: function () {
      $('#sidebar').toggleClass('active')
    }
  }
}
</script>

<style scoped lang="scss">
.app-header {
}
</style>
