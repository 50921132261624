<template lang="html">
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-category">
          <router-link class="sidebar-brand brand-logo" to="/">
        <img src="/static/img/logos/REDEMA_white.png" alt="logo" />
      </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link not-collapse" v-b-toggle="'dashboard-dropdown'">
            <i class="menu-icon typcn typcn-home"></i>
            <span class="menu-title">Edificios</span>
            <i class="menu-arrow"></i>
          </a>
          <b-collapse id="dashboard-dropdown" visible>
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'listBuildings' }">Listado</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'createBuilding' }">Añadir nuevo</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <a class="nav-link not-collapse" v-b-toggle="'about-dropdown'">
            <i class="menu-icon typcn typcn-info-large"></i>
            <span class="menu-title">Saber más</span>
            <i class="menu-arrow"></i>
          </a>
          <b-collapse id="about-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'conditions' }">Condiciones de uso</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'about' }">Acerca de SUPPORT</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'functionalities' }">Funcionalidades del Observatorio</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <a class="nav-link" v-b-toggle="'ui-basic'">
            <i class="menu-icon typcn typcn-user"></i>
            <span class="menu-title">Cuenta</span>
            <i class="menu-arrow"></i>
          </a>
          <b-collapse id="ui-basic" visible>
            <ul class="nav flex-column sub-menu">
              <li v-if="!isLoggedIn" class="nav-item">
                <a href="javascript:void()" @click="startLogout" class="nav-link" to="/buttons/">Entrar</a>
              </li>
              <li v-else class="nav-item">
                <a href="javascript:void()" @click="startLogout" class="nav-link" to="/dropdowns/">Salir</a>
              </li>
            </ul>
          </b-collapse>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script lang='js'>
import JQuery from 'jquery'
import { mapActions } from 'vuex'
import store from '@/store/index'
// eslint-disable-next-line
let $ = JQuery

export default {
  name: 'app-sidebar',

  computed: {
    isLoggedIn() {
      let isLoggedIn = store.getters['auth/isAuthenticated'];
      return isLoggedIn;
    },
  },

  mounted () {
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },

  methods: {
    ...mapActions('auth', ['logout']),

    async startLogout() {
      await this.logout();
      this.$router.push({ name: 'login' });
    },
  },
}
</script>

<style scoped lang="scss">
.sidebar {
    top: -50px;
    position: relative;
    z-index: 999999;
  }
@media screen and (max-width: 991px) {
  .sidebar {
    top: 63px;
    position: fixed;
    z-index: 999999;
  }
}
  .sidebar-icon-only .sidebar {
    top: 0px;
  }
  .sidebar-brand.brand-logo > img {
    max-width: 200px;
  }
</style>
